require('./bootstrap');

import { createApp } from 'vue';

let app = createApp()

// app.component('example-component', require('./components/ExampleComponent.vue').default);

app.mount("#app")


// Toastr 
window.toastr = require('toastr');

// select2

require('select2');

toastr.options.closeButton = true;
toastr.options.timeOut = 0;
toastr.options.extendedTimeOut = 0;
